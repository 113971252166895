import React, { useState } from 'react'
import LayoutInner from './layoutInner'
import { Formik } from 'formik'
import { isNonEmpty, isValidEmailAddress, isValidMessageLength, isValidNameLength } from '../utils'
import { keys } from 'lodash'
import { Button, Grid, TextField } from '@material-ui/core'
import * as amplitude from '@amplitude/analytics-browser'

const FORM_URL = "https://us-central1-three-is-a-pattern.cloudfunctions.net/contactFormSignup"

const ContactForm = () => {
    const [submitSuccess, setSubmitSuccess] = useState(false)

    return (
        <div>
            {!submitSuccess && (<LayoutInner>
                <br/><hr/><br/><br/>
                <h3>Quick contact form</h3>
                <p>Add your information below and we'll get back to you right away.</p>
                <Formik
                  initialValues={{
                      emailAddress: '',
                      firstName: '',
                      lastName: '',
                      organization: '',
                      contactReason: ''}}
                  validate={values => {
                      const errors = {};
                      const validators = {
                          emailAddress: [[isNonEmpty, 'Required'],
                              [isValidEmailAddress, 'Invalid email address'],
                              [isValidNameLength, 'This is too long']
                          ],
                          firstName: [
                              [isNonEmpty, 'Required'],
                              [isValidNameLength, 'This is too long']],
                          lastName: [[isValidNameLength, 'This is too long']],
                          organization: [[isValidNameLength, 'This is too long']],
                          contactReason: [[isValidMessageLength, 'This is too long']],
                      }

                      keys(validators).forEach(fieldName=>{
                          const fieldValidators = validators[fieldName]
                          // console.log(fieldName)
                          // console.log(fieldValidators)
                          for (let i= 0; i< fieldValidators.length; i++) {
                              // console.log(i)
                              const [isValidFn, errorMessage] = fieldValidators[i]
                              // console.log(isValidFn)
                              if (!isValidFn(values[fieldName])) {
                                  errors[fieldName] = errorMessage
                                  break
                              }
                          }
                      })

                      return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {

                      fetch(FORM_URL, {
                          method: 'POST',
                          headers: { 'Content-Type': 'application/json' },
                          body: JSON.stringify(values)
                      }).then(response => {
                          console.log(response.body)
                          setSubmitting(false);
                          setSubmitSuccess(true);
                          amplitude.track('Contact Form Success');
                      })

                      // setTimeout(() => {
                      //     alert(JSON.stringify(values, null, 2));
                      //     setSubmitting(false);
                      // }, 400);
                  }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                          <Grid container>
                              <Grid item xs={12} sm={10}>
                                  <Grid container spacing={2}>
                                      <Grid item xs={6}>
                                          <TextField name="firstName" label="First name"
                                                     error={errors.firstName && touched.firstName}
                                                     helperText={errors.firstName}
                                                     variant="outlined"
                                                     fullWidth
                                                     required
                                                     onChange={handleChange}
                                                     onBlur={handleBlur}
                                                     value={values.firstName}
                                          />
                                      </Grid>
                                      <Grid item xs={6}>
                                          <TextField name="lastName" label="Last name"
                                                     error={errors.lastName && touched.lastName}
                                                     helperText={errors.lastName}
                                                     variant="outlined"
                                                     fullWidth
                                                     onChange={handleChange}
                                                     onBlur={handleBlur}
                                                     value={values.lastName}
                                          />
                                      </Grid>
                                      <Grid item xs={12}>
                                          <TextField name="emailAddress" label="Work email address"
                                                     variant="outlined"
                                                     error={errors.emailAddress && touched.emailAddress}
                                                     helperText={errors.emailAddress}
                                                     fullWidth
                                                     required
                                                     onChange={handleChange}
                                                     onBlur={handleBlur}
                                                     value={values.emailAddress}
                                          />
                                      </Grid>
                                      <Grid item xs={12}>
                                          <TextField name="organization" label="Organization"
                                                     variant="outlined"
                                                     error={errors.organization && touched.organization}
                                                     helperText={errors.organization}
                                                     fullWidth
                                                     onChange={handleChange}
                                                     onBlur={handleBlur}
                                                     value={values.organization}
                                          />
                                      </Grid>
                                      <Grid item xs={12}>
                                          <TextField name="contactReason" label="How can we help?"
                                                     variant="outlined"
                                                     error={errors.contactReason && touched.contactReason}
                                                     helperText={errors.contactReason}
                                                     fullWidth
                                                     multiline
                                                     rows={4}
                                                     onChange={handleChange}
                                                     onBlur={handleBlur}
                                                     value={values.contactReason}
                                          />
                                      </Grid>
                                      <Grid item xs={12}>
                                          <Button variant={"contained"} color="primary"
                                                  type="submit"
                                                  disabled={isSubmitting || 0 < keys(errors).length}>Submit</Button>
                                      </Grid>
                                  </Grid>
                              </Grid>
                          </Grid>





                          {/*<span>{errors.emailAddress && touched.emailAddress && errors.emailAddress}</span>*/}



                      </form>
                    )}
                </Formik>
                <br/><br/>

            </LayoutInner>)}

            {submitSuccess && (
              <LayoutInner>
                  <br/><br/>
                  <h3>Thanks for reaching out. We'll follow up by email shortly.</h3>
                  <br/><br/>
              </LayoutInner>
            )}
        </div>
    )
}

export default ContactForm
